<template>
  <svg :class="spinnerClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="grey" stroke-width="4"></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    h?: string
    w?: string
  }>(),
  {
    h: '5',
    w: '5',
  }
)

// If your size classes are not working you may need to add the utility classes to the safelist.
// https://tailwindcss.com/docs/content-configuration#safelisting-classes
const spinnerClass = computed(() => {
  return `-ml-1 mr-3 h-${props.h} w-${props.w} animate-spin text-white`
})
</script>
